import {getreportList ,getExport } from "./api";
import Util from "@/utils/utils";
import { queryRepairOrderInfo } from "@/views/installationRepairReporting/workOrderSubmission/api";
export default {
  components: {
  },
  data() {
    return {
      nolistImg: require("@/assets/IntentionList/none.png"),
      pageLoadFlag: false,
      isLoading:false,
      arg:{},
      tableList:[
        {

        }
      ],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },

        {
          path: "/installationRepairReporting/repairReportinglist",
          name: "",
          title: "工单查询"
        }
      ],
      totalPages:0,
      totalRows:0,
      filterFrom: {
        pageNo: 1,
        pageSize: 9,
      },
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 如果是草稿状态的话 跳转工单提报页面 row.reportStatus == 5是草稿状态
    gotoSumbit(row) {
      if (row.reportStatus == 5 ) {
        this.$router.push({
          path: "/installationRepairReporting/workOrderSubmission",
          query: {
            // item: JSON.stringify(this.item),
            // proItem: JSON.stringify(e),
            id:row.id,
            type:'4',
          }
        })

      }

    },
    // 导出
    exportClick() {
      let data = {
        ...this.arg
      }
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "触达记录.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    searchFun(arg) {
      this.arg = arg[0];
      this.filterFrom.pageNo = 1
      this.getList();
    },
    loadMore() {
      this.filterFrom.pageNo =  this.filterFrom.pageNo + 1,
      this.getList()
    },
    getList(){
      this.pageLoadFlag=true
      let data ={
        pageSize: this.filterFrom.pageSize,
        pageNo:  this.filterFrom.pageNo,
        ...this.arg
      }
      getreportList(data).then(res=>{
        if(res.data.code == 0) {
          this.totalRows = res.data.totalRows
          this.totalPages = res.data.totalPages
          this.currentPage =  res.data.currentPage
          if( this.filterFrom.pageNo > 1 && res.data.list.length>0) {
            this.tableList = this.tableList.concat(res.data.list);
          } else {
            this.tableList = res.data.list
          }
          if(res.data.totalPages == 0) {
            this.tableList = []
          }
        } else {
          this.tableList = []
          this.totalPages = 0
          this.$message.warning('查询失败'+res.data.msg)
        }
        this.pageLoadFlag=false
      }).catch(error=>{
        this.pageLoadFlag=true
      })
    }
  },


};
